
export default {
    route: {
        path: '/',
        routes: [
            {
                name: '首页',
                path: '/',
            },
            {
                name: '主站',
                path: 'https://www.arsrna.cn'
            },
            {
                name: '自然语言',
                path: '/text',
                routes: [{
                    name: '篇章分析',
                    path: '/text/tia/',
                }, {
                    name: '智能对话',
                    path: '/text/chat/'
                }]
            },
            {
                name: '图像',
                path: '/img',
                children: [{
                    type: 'group',
                    name: '分析',
                    children: [{
                        name: '审核',
                        path: '/img/shenhe/',
                        component: <h1>111s</h1>
                    }, {
                        name: '图像标签',
                        path: '/img/label/'
                    }, {
                        name: '人脸分析',
                        path: '/img/face-analyze/'
                    }]
                }, {
                    type: 'group',
                    name: '处理',
                    children: [{
                        name: '人像试妆',
                        path: '/img/fmu/'
                    }, {
                        name: '增强与超分辨率',
                        path: '/img/enhance/'
                    }],
                }]
            },
            {
                name: '艺术',
                path: '/art',
                children: [{
                    name: 'StableDiffusion sls',
                    path: '/art/sd/',
                }, {
                    type: 'group',
                    name: '基础云算力',
                    children: [{
                        name: '绘画（暂停服务）',
                        path: '/error',
                    }]
                }
                ]
            }],
    },
    location: {
        pathname: '/',
    },
};