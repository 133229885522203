import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Error from './error';
import menu from './Menu'

import {
  Layout, Watermark,
  Avatar, Typography, Row, Col, Divider, Space,
  Dropdown, Button
}
  from 'antd';
import { GithubOutlined, GlobalOutlined, UserOutlined } from '@ant-design/icons'
import { uuid } from './plug';
import Home from './Home';
import './App.css';
import Aegis from 'aegis-web-sdk';
import md5 from 'md5'
import { ProLayout } from '@ant-design/pro-components';
import cookie from 'react-cookies';
import { ArCIAM, handleLogin, handleLogout, parseIdToken } from './login';
const { Header, Content, Footer } = Layout;
const { Paragraph, Title } = Typography


function Main() {
  useEffect(() => {
    ArCIAM.handleGetToken();
  }, []);


  global.setTitle = function (name) {
    document.title = `${name} - ArSrNa AI中心`
  }
  const navigate = useNavigate();
  const [pathname, setPathname] = useState('/');
  const login = !(cookie.load('CIAM') === void '💿');
  return (
    <>
      <Watermark content="Ar-Sr-Na Learn">
        <ProLayout
          title="Ar-Sr-Na 前端学习"
          logo={require('./icon.ico')}
          layout="top"
          fixedHeader={true}
          menu={{
            type: "group"
          }}
          disableMobile
          location={{
            pathname
          }}
          menuItemRender={(item, dom) => (
            <div
              onClick={() => {
                navigate(item.path);
                setPathname(item.path);
              }}
            >
              {dom}
            </div>
          )}
          {...menu}
          actionsRender={() => (
            <div style={{ paddingRight: 10 }}>
              {login ? <>
                <Dropdown menu={{
                  items: [{
                    key: 'manageUser',
                    label: (<a target="_blank" href="https://user.arsrna.cn/">个人中心</a>),
                  }, {
                    key: 'logout',
                    label: (<a target="_blank" onClick={handleLogout}>退出登录</a>),
                  }]
                }}
                  arrow
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Avatar src={`https://cravatar.cn/avatar/${md5(parseIdToken().payload.email)}`} style={{ marginRight: 8 }} />
                    {parseIdToken().payload.nickname}</a>
                </Dropdown>
              </> :
                <Button onClick={handleLogin}><UserOutlined /> 请登录</Button>
              }
            </div>)}
        >
          <div style={{ minHeight: '60vh' }}>
            <Content>
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path="*" element={<Error />} replace={true} />
                {/* Demos */}
              </Routes>
            </Content>
          </div>
          {/* <PageContainer /> */}
          <FooterContent />
        </ProLayout>

      </Watermark >
    </>
  )
}

function FooterContent() {
  return (
    <>
      <Divider />
      <Row gutter={16}>
        <Col span={8}>
          <Space direction='vertical'>
            <Title level={4}>关于</Title>
            <a target="_blank" href='https://www.arsrna.cn/' rel='noreferrer'><GlobalOutlined /> Ar-Sr-Na 网站</a>
            <a target="_blank" href='https://www.arsrna.cn/communication/' rel='noreferrer'><GlobalOutlined /> 联系我们</a>
            <a target="_blank" href='https://github.com/ArSrNa/' rel='noreferrer'><GithubOutlined /> github</a>
            <a target="_blank" href='https://arsrna.coding.net/public/' rel='noreferrer'><GlobalOutlined /> Coding</a>
          </Space>
        </Col>

        <Col span={8}>
          <Space direction='vertical'>
            <Title level={4}>开发</Title>
            <a target="_blank" href='https://www.arsrna.cn/app/' rel='noreferrer'>资源与应用中心</a>
            <a target="_blank" href='https://ai.arsrna.cn/' rel='noreferrer'>AI 中心</a>
            <a target="_blank" href='https://live.arsrna.cn/' rel='noreferrer'>音视频</a>
          </Space>
        </Col>

        <Col span={8}>
          <Space direction='vertical'>
            <Title level={4}>更多</Title>
            <a target="_blank" href='https://www.arsrna.cn/' rel='noreferrer'>Ar-Sr-Na 博客</a>
            <a target="_blank" href='https://www.arsrna.cn/app/esrgan/' rel='noreferrer'>ESRGANUI 超分辨率</a>
            <a target="_blank" href='https://www.arsrna.cn/app/catch/' rel='noreferrer'>异步采集</a>
          </Space>
        </Col>
      </Row>
      <div style={{ paddingTop: 20, textAlign: 'center' }}><a href='https://beian.miit.gov.cn/' target='_blank'>琼ICP备2023005694号-1</a></div>
      <div style={{ textAlign: 'center' }}>Powered by Ar-Sr-Na</div>
    </>
  )
}


function App() {
  // consoleDialog()
  const aegis = new Aegis({
    id: 'W7oejTaL4r0ek1Yg67', // 上报 id
    uin: `ArLearn ${uuid()}`, // 用户唯一 ID（可选）
    reportApiSpeed: true, // 接口测速
    reportAssetSpeed: true, // 静态资源测速
    spa: true, // spa 应用页面跳转的时候开启 pv 计算
    hostUrl: 'https://rumt-zh.com'
  });
  return (
    <Router>
      <Main />
    </Router>
  );
}

export default App;

