import { Space, Card, Button, Image, Avatar, Typography, Anchor, Row, Col, Collapse } from 'antd';
import { useNavigate } from 'react-router-dom';
import { GithubOutlined, GlobalOutlined } from '@ant-design/icons';
import Error from './error';
const { Meta } = Card;
const { Title, Paragraph } = Typography;


export default function Home() {
    const navigate = useNavigate();

    const appRes = [{
        // img: 'https://www.rehiy.com/usr/uploads/2023/10/2144835887.png',
        // title: 'OpenTDP-云边拨测',
        // description: '这是一个将自己的节点注册到 OpenTDP 云边拨测集群的服务。',
        // actions: [
        //     <a onClick={() => navigate('/opentdp/blackbox/')} >节点状态</a>,
        //     <a href="https://blackbox.opentdp.org/provide_nodes" target='_blank'><GlobalOutlined /> 注册节点</a>,
        //     <a href="https://blackbox.opentdp.org/" target='_blank'><GlobalOutlined /> 了解更多</a>
        // ]
    },]

    return (
        <>
            <Space direction='vertical' style={{ width: '100%' }} size="large">
                <div>
                    <div style={{ zIndex: 1, position: 'absolute', padding: 50, color: 'white' }}>
                        <div className='title' style={{ letterSpacing: 4 }}>轻松秒杀</div>
                        <span className='lead'>ArLearn项目</span>
                    </div>
                    <img src={require('./images/index.jpg')} className='hdpic' />
                </div>

                <section>
                    <div className='anchor'>
                        <Anchor
                            direction="horizontal"
                            items={[{
                                key: '前端入门',
                                href: '#frontend',
                                title: '前端入门',
                            }, {
                                key: '前端网络',
                                href: '#fen',
                                title: '前端网络',
                            }, {
                                key: '前端工程化',
                                href: '#fep',
                                title: '前端工程化',
                            },]}
                        />
                    </div>

                    <div id='frontend'>
                        <Title level={2}>前端入门</Title>
                        <Title level={4}>直接入门</Title>
                        <Collapse items={[{
                            key: '何为前端',
                            label: '何为前端？',
                            children: <>
                                既然决定学习这部分内容，前端这部分的介绍当然必不可少。<br />
                                你所见到的页面，正是<b>前端</b>。<br />
                                所以，前端指的是一个网站或者应用的前台部分，最直接展现给用户进行浏览和操作的部分。
                            </>
                        }, {
                            key: '为什么要学习前端',
                            label: '为什么要学习前端？',
                            children: <>
                                这个问题我相信你应该比我清楚。现在展现在你面前的网站，就是它的前端。
                                <br />或许你需要写一个网站，展示一个个人简历，一个酷炫的特效，一段3d动画，或者出一些文章，博客，分享等等，完成这些，你都应该学习前端。
                            </>
                        }, {
                            key: '前端需要了解什么内容（入门）',
                            label: '前端需要了解什么内容（入门）？',
                            children: <>
                                前端包括的技术太多了，如果只是写个小页面，那html, js, css完全足够。
                                <br />当然，随着页面内容不断丰富，框架当然少不了，前端目前已有数百万的框架，挑选自己适合的现代框架或许对前端开发更有帮助。
                                <br />如果一两个页面已经无法满足你的需要，要写大工程，写一个后台，甚至一个游戏的时候，技术圈还要再度扩展，
                                你需要了解事件循环、响应式、渲染原理、前端网络等更深层次的知识。
                                <br />那么接下来，祝你通过了第一关，认识到了前端的意义！
                            </>
                        },]} />

                        <Row gutter={16}>
                            {appRes.map(res => {
                                const { img, title, description, actions } = res
                                return (
                                    <Col md={12} lg={8}>
                                        <Card
                                            cover={img ? <img alt={title} src={img} style={{ height: 200, width: '100%', justifyContent: 'center', objectFit: 'scale-down' }} /> : undefined}
                                            actions={actions}
                                        >
                                            <Meta
                                                title={title}
                                                description={description}
                                            />
                                        </Card></Col>
                                )
                            }
                            )}
                        </Row>
                    </div>


                    <div id='fen'>
                        <Title level={2}>前端网络</Title>
                    </div>

                    <Error />

                </section>
            </Space>
        </>
    )
}